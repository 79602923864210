/* eslint-disable import/prefer-default-export */
export const IDR = (value, min = 0, max = 0) => `Rp${new Intl.NumberFormat('id-ID', {
  maximumFractionDigits: max,
  minimumFractionDigits: min,
}).format(value)}`

export const IDRWithoutLbl = value => new Intl.NumberFormat('id-ID', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}).format(value)
