<template>
  <b-col
    md="12"
    lg="6"
    cols="12"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity="0.3"
    >
      <b-card
        no-body
        class="lg:h-[39rem] md:h-[45rem] xl:h-[45rem]"
      >
        <div class="flex justify-content-between p-1">
          <p class="text-2xl font-semibold">
            Top Admin Order</p>
          <div class="w-40">
            <b-form-select
              v-model="filterRangking"
              :options="filterRangkingOptions"
              @change="getTopAdminOrder(), getTrackingSales()"
            />
          </div>
        </div>
        <b-tabs>
          <b-tab
            v-if="profile.partner_is_tracking_sales_dashboard"
            :disabled="!profile.partner_is_tracking_sales_dashboard"
          >
            <template slot="title">
              <div
                class="flex"
              >
                <p class="mb-0">
                  Admin Penjualan</p>
                <img
                  id="infoAdminSales"
                  class="ml-[5px] my-auto"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                >
                <b-popover
                  triggers="hover"
                  target="infoAdminSales"
                  placement="bottomleft"
                >Ranking Admin Penjualan berdasarkan tim yang ditambahkan pada fitur "Pelacakan Penjualan"</b-popover>
              </div>
            </template>
            <div
              class="h-[370px]"
              style="overflow-y:auto;overflow-x:hidden"
            >
              <p
                v-if="trackingSales.length < 1"
                class="text-center mb-1"
              >Tidak ada data yang dapat ditampilkan.</p>
              <b-row
                v-for="(data, index) in trackingSales"
                :key="index"
                class="p-1 border-b-2"
              >
                <b-col
                  cols="2"
                  class="text-right"
                >
                  <b-avatar class="ml-auto" />
                </b-col>
                <b-col
                  cols="6"
                  class="pl-0"
                >
                  <p class="mb-0 my-auto">
                    {{ data.name }}</p>
                </b-col>
                <b-col
                  cols="4"
                  class="text-right"
                >
                  <p class="mb-0 font-semibold text-success">
                    {{ IDR(data.total_transactions) }}
                  </p>
                  <small>{{ data.total_order }} order</small>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab>
            <template slot="title">
              <div class="flex">
                <p class="mb-0">
                  Input Order</p>
                <img
                  id="infoInputOrder"
                  class="ml-[5px] my-auto"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                >
                <b-popover
                  triggers="hover"
                  target="infoInputOrder"
                  placement="bottomleft"
                >Ranking admin yang menginputkan order paling banyak berdasarkan nilai orderan.
                  Untuk menampilkan lebih dari satu admin kamu perlu menggunakan fitur akses akun</b-popover>
              </div>
            </template>
            <div
              class="h-[370px]"
              style="overflow-y:auto;overflow-x:hidden"
            >
              <p
                v-if="topAdminOrder.length < 1"
                class="text-center mb-1"
              >Tidak ada data yang dapat ditampilkan.</p>
              <b-row
                v-for="(data, index) in topAdminOrder"
                :key="index"
                class="p-1 border-b-2"
              >
                <b-col
                  cols="2"
                  class="text-right"
                >
                  <b-avatar class="ml-auto" />
                </b-col>
                <b-col
                  cols="6"
                  class="pl-0"
                >
                  <p class="mb-0 my-auto">
                    {{ data.admin_name }}</p>
                </b-col>
                <b-col
                  cols="4"
                  class="text-right"
                >
                  <p class="mb-0 font-semibold text-success">
                    {{ IDR(data.net_profit) }}</p>
                  <small>{{ data.total_order }} order</small>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </b-col>
</template>
<script>
import { mapState } from 'vuex'
import { IDR } from '@/libs/currency'

export default {
  data() {
    return {
      loading: false,
      topAdminOrder: [],
      trackingSales: [],
      filterRangking: 1,
      filterRangkingOptions: [
        {
          value: 1,
          text: 'Bulan ini',
        },
        {
          value: 2,
          text: 'Tahun ini',
        },
        {
          value: '',
          text: 'Life time',
        },
      ],
      IDR,
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
  },
  mounted() {
    this.getTrackingSales()
    this.getTopAdminOrder()
  },
  methods: {
    async getTopAdminOrder() {
      this.loading = true
      try {
        const response = await this.$http_new.get(
          'komship/api/v1/dashboard/partner/topAdminOrder', {
            params: { filter: this.filterRangking },
          },
        )
        const { data } = response.data
        this.topAdminOrder = data
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    async getTrackingSales() {
      this.loading = true
      try {
        const response = await this.$http_new.get(
          'komship/api/v1/dashboard/partner/rankingTrackingSales', {
            params: { filter: this.filterRangking },
          },
        )
        const { data } = response.data
        this.trackingSales = data
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>
<style>
.nav-tabs {
  border-bottom: 1px solid #E2E2E2;
}
</style>
